<template>
    <OModal 
        name="newPrerequisiteDlg" 
        ref="newPrerequisiteDlg" 
        @shown="() => { 
            newPrerequisiteObj.orgunit_id = myContext.id; 
            newPrerequisiteObj.orgunit = myContext.name;
            newPrerequisiteObj.orgunit_idPath = myContext.idPath;
            newPrerequisiteObj.orgunit_accessIdPath = myContext.idPath;
        }"
        @hidden="resetOnClose"
    >
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-titel">{{ $t('Add new prerequisite') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <label for="prerequisiteName">{{ $t('Name') }} *</label>
                                <input
                                    id="prerequisiteName" 
                                    class="form-control" 
                                    :class=" { 'border border-danger border-2': missingFields.name }" 
                                    v-model="newPrerequisiteObj.name"
                                    :disabled="prereqCreated"
                                />
                            </div>
                            <div class="col-6">
                                <label for="prerequisiteDescription">{{ $t('Description') }}</label>
                                <input
                                    id="prerequisiteDescription" 
                                    class="form-control" 
                                    v-model="newPrerequisiteObj.description"
                                    :disabled="prereqCreated"
                                />
                            </div>                  
                        </div>
                        <div class="row mt-3">
                            <div class="col-6">
                                <label for="prerequisiteOrgUnit">{{ $t('Org. unit') }} *<span v-if="orgunitOutOfContext" :title="$t('You are about to add a prerequisite to an orgunit outside of the current context you are in.')"><i class="bi bi-exclamation-triangle-fill" style="color:red;"></i></span></label>
                                <OOrgUnitsLookup 
                                    :bind="(sel: DataItemModel) => { 
                                        newPrerequisiteObj.orgunit = sel.OrgUnit; newPrerequisiteObj.orgunit_id = sel.ID; 
                                        newPrerequisiteObj.orgunit_idPath = sel.IdPath; newPrerequisiteObj.orgunit_accessIdPath = sel.IdPath;
                                    }"
                                >
                                    <template #orgunit>
                                        <input 
                                            id="prerequisiteOrgUnit" 
                                            :class=" { 'border border-danger border-2': missingFields.orgunit }" 
                                            class="form-select" 
                                            :value="newPrerequisiteObj.orgunit"
                                            :disabled="prereqCreated"
                                        />
                                    </template>
                                </OOrgUnitsLookup>
                            </div>     
                            <div class="col-6">
                                <label for="prerequisiteStatus">{{ $t('Status') }}</label>
                                <ODataLookup
                                    :dataObject="local_dsStatusLkp"
                                    :bind="(sel: DataItemModel) => { newPrerequisiteObj.status = sel.Name; newPrerequisiteObj.status_id = sel.ID; }"
                                    v-model="newPrerequisiteObj.status"
                                    id="prerequisiteStatus"
                                    class="form-select"
                                    :disabled="prereqCreated"
                                >   
                                    <OColumn field="ID" width="65" />
                                    <OColumn field="Name" width="150" :headerName="$t('Name')" />
                                </ODataLookup>
                            </div>             
                        </div>
                        <div class="row mt-3">
                            <div class="col-6">
                                <label for="prerequisiteComments">{{ $t('Comments') }}</label>
                                <input
                                    id="prerequisiteComments" 
                                    class="form-control" 
                                    v-model="newPrerequisiteObj.comments"
                                    :disabled="prereqCreated"
                                />
                            </div>
                            <div class="col-6">
                                <label for="prerequisiteValDescription">{{ $t('Validity Description') }}</label>
                                <input
                                    id="prerequisiteValDescription" 
                                    class="form-control" 
                                    v-model="newPrerequisiteObj.validityDescription"
                                    :disabled="prereqCreated"
                                />
                            </div>                  
                        </div>
                        <div class="row mt-3">
                            <div class="col-6">
                                <label for="prerequisiteSigForRisk">{{ $t('Significance For Risk') }}</label>
                                <input
                                    id="prerequisiteSigForRisk" 
                                    class="form-control" 
                                    v-model="newPrerequisiteObj.significanceForRisk"
                                    :disabled="prereqCreated"
                                />
                            </div>
                            <div class="col-6">
                                <label for="prerequisiteObject">{{ $t('Object/location') }}</label>
                                <input
                                    id="prerequisiteObject" 
                                    class="form-control" 
                                    v-model="newPrerequisiteObj.object"
                                    :disabled="prereqCreated"
                                />
                            </div>                  
                        </div>
                        <div class="row mt-3">
                            <div class="col-6">
                                <label for="prerequisiteFollowUpResp">{{ $t('Follow Up Responsible') }}</label>
                                <OPersonsLookup 
                                    :bind="(sel: DataItemModel) => { newPrerequisiteObj.followUpResp_id = sel.ID, newPrerequisiteObj.followUpResp = sel.Name }"
                                >
                                    <template #person>
                                        <input 
                                            id="prerequisiteFollowUpResp" 
                                            class="form-select form-control" 
                                            v-model="newPrerequisiteObj.followUpResp"
                                            :disabled="prereqCreated"
                                        >
                                    </template>
                                </OPersonsLookup>
                            </div>
                            <div class="col-6">
                                <label for="prerequisiteFollowUpDate">{{ $t('FollowUp Due Date') }}</label>
                                <ODatePicker 
                                    class="form-control"
                                    id="prerequisiteFollowUpDate" 
                                    date 
                                    v-model="newPrerequisiteObj.followUpDate" 
                                    format="Short Date" 
                                    :placeholder="$t('Select date')" 
                                    :disabled="prereqCreated"
                                />
                            </div>                  
                        </div>
                        <div class="row mt-2 ms-1">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :disabled="prereqCreated" v-model="newPrerequisiteObj.valid" id="prerequisiteValid">
                                <label class="form-check-label" for="prerequisiteValid">
                                    Valid
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-secondary" data-bs-dismiss="modal">{{ $t(!prereqCreated ? 'Cancel' : 'Close') }}</button>
                    <button v-if="!prereqCreated" class="btn btn-primary" @click="addNewPrerequisite">{{ $t('Add prerequisite') }}</button>
                    <a v-else class="btn btn-success" :href="`/ramsprerequisite?ID=${prereqCreatedID}`" target="_blank"><i class="bi bi-box-arrow-up-right"></i> {{ $t('Open created prerequisite') }} </a>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup lang="ts">
    import { getOrCreateDataObject, deleteDataObject, type DataItemModel, type DataObjectModels } from 'o365-dataobject';
    import { type MissingFields, type NewPrerequisiteObj    } from 'rams.libraries.vue.components.interfaces.NewPrerequisiteInterfaces.ts'
    import { getOrCreateProcedure, type IProcedureOptions   } from 'o365-modules';
    import { reactive, onUnmounted, ref, watch  } from 'vue';
    import { context as myContext               } from 'o365-modules';
    import { OPersonsLookup, OOrgUnitsLookup    } from "o365-system-lookups"; 
    import { $t     } from 'o365-utils';
    import { alert  } from 'o365-vue-services';
    import { Modal  } from 'bootstrap'

    const props = defineProps({
        "SourceID": {
            type: Number, 
            default: null,
            required: false
        }
    })
    const emits = defineEmits(["newPrereqID"]);
    const newPrerequisiteDlg = ref<Modal | null>(null);
    const prereqCreated = ref<boolean>(false);
    const prereqCreatedID = ref<number | null>(null);
    const orgunitOutOfContext = ref<boolean>(false);

    const procNewPrerequisite = new getOrCreateProcedure<IProcedureOptions>({ id:"procNewPrerequisite", procedureName: "astp_RAMS_SourceNewPrerequisite" });

    const initial_missingFields: MissingFields = {
        name: false,
        orgunit: false
    }
    const missingFields = reactive<MissingFields>({...initial_missingFields});

    const initial_newPrerequisiteObj: NewPrerequisiteObj = {
        name: "",
        description: "",
        orgunit: "", 
        orgunit_id: null,
        orgunit_idPath: null, 
        orgunit_accessIdPath: null,
        status: "",
        status_id: null,
        comments: "",
        validityDescription: "",
        significanceForRisk: "",
        object: "",
        followUpResp: "",
        followUpResp_id: null,
        followUpDate: null,
        valid: true
    }
    const newPrerequisiteObj = reactive<NewPrerequisiteObj>({...initial_newPrerequisiteObj});

    const local_dsStatusLkp = getOrCreateDataObject<DataObjectModels>({
        id: 'dsStatusLkp',
        viewName: 'atbv_RAMS_PrerequisiteStatuses',
        maxRecords: 10,
        fields:
            [
                {name: "Name", type: "string" },
                {name: "ID", type: "int" }, 
                {name: "IsExpired", type: "boolean"}
            ]
    })

    watch(newPrerequisiteObj, (newVal: NewPrerequisiteObj) => {
        if(newVal.orgunit_idPath) {
            checkOrgOutsideOfContext(newVal.orgunit_idPath, myContext.idPath);
        }
    });

    // watch(myContext, (newVal) => {
        // if(newVal.idPath) {
            // checkOrgOutsideOfContext(newPrerequisiteObj.orgunit_idPath, newVal.idPath);
        // }
    // });

    watch(newPrerequisiteObj, (newVal) => {
        if(newVal.name.length > 0) missingFields.name = false;
        if(newVal.orgunit_id !== null) missingFields.orgunit = false; 
    });

    const checkOrgOutsideOfContext = (newPrereqObjIdPath: string, contextIdPath: string) => {
        if(!newPrereqObjIdPath.startsWith(contextIdPath)) {
            orgunitOutOfContext.value = true;
        } else {
            orgunitOutOfContext.value = false;
        }
    }

    const addNewPrerequisite = () => {
        if(!newPrerequisiteObj.name) {
            missingFields.name = true;
            return alert($t("Name is required"), 'warning', { autohide: true, delay: 3000})
        }
        
        if(!newPrerequisiteObj.orgunit_id) {
            missingFields.orgunit = true;
            return alert($t("OrgUnit is required"), 'warning', { autohide: true, delay: 3000})
        }

        procNewPrerequisite.execute({
            Source_ID: props.SourceID,
            OrgUnit_ID: newPrerequisiteObj.orgunit_id,
            Name: newPrerequisiteObj.name,
            Description: newPrerequisiteObj.description,
            Comments: newPrerequisiteObj.comments,
            ValidityDescription: newPrerequisiteObj.validityDescription,
            SignificanceForRisk: newPrerequisiteObj.significanceForRisk,
            Location: newPrerequisiteObj.object,
            FollowUpResponsible_ID: newPrerequisiteObj.followUpResp_id,
            FollowUpDueDate: newPrerequisiteObj.followUpDate,
            Valid: Number(newPrerequisiteObj.valid),
            Status_ID: newPrerequisiteObj.status_id
        }).then((response: any) => {
            if(response.Table[0]["ID"]) {
                    alert($t('Prerequisite was successfully created.'), 'success', {autohide: true, delay: 3000});
                    emits("newPrereqID", response.Table[0]["ID"]);
                    prereqCreated.value = true;
                    prereqCreatedID.value = response.Table[0]["ID"];
                }
        }).catch((ex: string) => {
            alert(ex);
        })
    }

    const resetOnClose = () => {
        Object.assign(newPrerequisiteObj, initial_newPrerequisiteObj);
        Object.assign(missingFields, initial_missingFields);
        prereqCreated.value = false;
        prereqCreatedID.value = null;
    }

    onUnmounted(() => {
        deleteDataObject(local_dsStatusLkp.id, local_dsStatusLkp.appId)
    });
</script>